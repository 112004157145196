body1 {
    /*background: #9ba8b6;*/
    background:rgba(133, 230, 124, 0.562);
    position: relative;
    height: 100vh;
}

.form--user__icon span{
    font-size: 32px;
    position: absolute !important;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
}
.login-container {
    max-width:480px;
    width: 80%;
    margin: 0 auto;
    background: hsl(113deg 18% 61%);
    border-radius: 4px;
    position: absolute;
    top: 50%;
    z-index: 2;
    color: #fff;
    content: '';
    padding: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
}


.form--user__icon {
    border-radius: 50%;
    height: 100px;
    z-index: 9;
    top: -50px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    background: #ea7a1a;
    width: 100px;
    color: #fff;
}

.login-container button {
    width: 100%;
    color:#fff !important;
    border-radius: 50px !important;
    border: 0 !important;
    background: #003296 !important;
}

.login-container h4 {
    margin-top: 32px;
}

.login-container form a{
    color:#fff !important;
    text-decoration:underline !important;
}